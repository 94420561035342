<script setup lang="ts">
const emit = defineEmits<{
  updateModel: [value: boolean];
}>();

const props = defineProps<{
  modelValue?: boolean;
}>();

const model = useVModel(props, "modelValue", emit, {
  passive: true,
  defaultValue: false,
});
</script>

<template>
  <span class="w-[1.125rem] h-[1.125rem] flex items-center justify-center">
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-4 h-4 object-contain"
    >
      <path
        d="M13.1568 8.67339C14.2545 7.59776 15.367 6.30848 15.367 4.62136C15.367 3.5467 14.9401 2.51605 14.1802 1.75615C13.4203 0.996245 12.3896 0.569336 11.3149 0.569336C10.0183 0.569336 9.10475 0.937702 7.99965 2.0428C6.89455 0.937702 5.981 0.569336 4.68435 0.569336C3.60969 0.569336 2.57904 0.996245 1.81914 1.75615C1.05923 2.51605 0.632324 3.5467 0.632324 4.62136C0.632324 6.31585 1.73742 7.60513 2.84252 8.67339L7.99965 13.8305L13.1568 8.67339Z"
        stroke-linecap="round"
        :class="[
          'hover:hidden',
          'group-hover/button:hidden',
          'stroke-current',
          { hidden: model, block: !model },
        ]"
      />

      <path
        d="M13.6 8.8C14.792 7.632 16 6.232 16 4.4C16 3.23305 15.5364 2.11389 14.7113 1.28873C13.8861 0.46357 12.767 0 11.6 0C10.192 0 9.2 0.4 8 1.6C6.8 0.4 5.808 0 4.4 0C3.23305 0 2.11389 0.46357 1.28873 1.28873C0.46357 2.11389 0 3.23305 0 4.4C0 6.24 1.2 7.64 2.4 8.8L8 14.4L13.6 8.8Z"
        :class="[
          'hover:block',
          'group-hover/button:block',
          'hover:!fill-[#95AAD280]',
          'group-hover/button:!fill-[#95AAD280]',
          {
            hidden: !model,
            'block fill-blue': model,
          },
        ]"
      />
    </svg>
  </span>
</template>
